const categoryElements = document.querySelectorAll('.js-change-products');
const productsContainer = document.querySelector('#products_container');

categoryElements.forEach(function (category) {
    category.addEventListener('click', function (event) {
        const termTagId = event.target.getAttribute('data-term-tag-id');
        const termId = event.target.getAttribute('data-term-id');

        categoryElements.forEach(function (item) {
            item.classList.remove('-active');
        });

        this.classList.toggle('-active');
        handleForm(termId, termTagId);
    });
});

function handleForm(termId, termTagId) {
    const preloader = document.querySelector('.preloader');

    var formData = new FormData();

    formData.append('action', 'mda_get_products_ajax');
    formData.append('term_id', termId);
    formData.append('term_tag_id', termTagId);

    var xhr = new XMLHttpRequest();
    xhr.open('POST', ajax.url);

    xhr.onloadstart = function () {
        // productsContainer.classList.add('-loading');
        if (preloader) {
            preloader.classList.add('-active');
        }
    };

    xhr.onload = function () {
        if (xhr.status === 200) {
            var products = productsContainer.querySelectorAll('.product-box');
            products.forEach(function (product) {
                product.classList.remove('product-slide-down');
            });

            productsContainer.innerHTML = xhr.responseText;
            var newProducts =
                productsContainer.querySelectorAll('.product-box');

            newProducts.forEach(function (product) {
                product.classList.add('product-slide-down');
            });
        } else {
            console.log('Błąd zapytania: ' + xhr.status);
        }
    };

    xhr.onloadend = function () {
        // productsContainer.classList.remove('-loading');
        if (preloader) {
            preloader.classList.remove('-active');
        }
    };

    xhr.send(formData);
}
